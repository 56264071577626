import { createAction } from "@reduxjs/toolkit";
import { END, eventChannel } from "redux-saga";
import { call, put, take, takeLatest } from "redux-saga/effects";
import { io } from "socket.io-client";
import { setData } from "../slices/sagaSlice";

const receiveMessage = (socket) => {
  socket.on("disconnect", () => {
    socket.connect();
    console.log("socket disconnected");
  });
  return eventChannel((emitter) => {
    socket.on("seconds", (msg) => {
      emitter(msg);
    });
    return () => {
      emitter(END);
    };
  });
};

const runOurAction = function* () {
  const socket = io("http://192.168.1.51:9999");
  const chan = yield call(receiveMessage, socket);

  while (true) {
    try {
      const value = yield take(chan);
      yield put(setData(value));
    } catch (err) {
      console.error("socket error:", err);
    }
  }
};

function* sagaSetDataWatcher() {
  yield takeLatest(sagaSetDataAction, runOurAction);
}
const sagaSetDataAction = createAction("sagaSlice/setDataWatch");

export { sagaSetDataWatcher, sagaSetDataAction };
