/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Checkbox, CheckboxProps, Menu, Pagination, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import ArrowUpIconComponent from '../../assets/icons/ArrowUpIcon';
import ArrowDownCircleIconComponent from '../../assets/icons/ArrowDownCircleIcon';
import ArrowDownRightIconComponent from '../../assets/icons/ArrowDownRightIcon';
import ItemCardComponent from '../../components/item-component/item-card-component';
import { useParams, useSearchParams } from 'react-router-dom';
import HomeService from '../../services/home';



function ListProductForm() {

    const { slug } = useParams();
    const [subCategory, setSubCategory] = useState([]);
    const [productManufacturers, setProductManufacturers] = useState([]);
    const [products, setProducts] = useState([]);
    const [attributeGroups, setAttributeGroups] = useState([]);
    const [total, setTotal] = useState();
    const [listCateProd, setListCateProd] = useState([]);
    const [listCate, setListCate] = useState([]);
    const [cateName, setCateName] = useState('');
    const [subCateName, setSubCateName] = useState('');
    const [subSubCateName, setSubSubCateName] = useState('');
    const [cateNameSlug, setCateNameSlug] = useState('');
    const [subCateNameSlug, setSubCateNameSlug] = useState('');
    const [subSubCateNameSlug, setSubSubCateNameSlug] = useState('');

    const [showMore, setShowMore] = useState(false);
    const onChange: CheckboxProps['onChange'] = (e) => {
    };
    const [searchParams] = useSearchParams();
    const selectedValue = searchParams.get('priceRange') || null;
    const min = searchParams.get('min');
    const max = searchParams.get('max');
    const onChangePrice = (value: string, minValue: number, maxValue: number) => {
        window.location.href = `/danh-muc/${slug}?min=${minValue * 1000}&max=${maxValue * 1000}&priceRange=${value}`;
    };

    const handlePriceChange = (value: string) => {
        // setSelectedValue(value);
        switch (value) {
            case 'under200':
                onChangePrice('under200', 0, 200);
                break;
            case '200to500':
                onChangePrice('200to500', 200, 500);
                break;
            case '500to800':
                onChangePrice('500to800', 500, 800);
                break;
            case '800to1000':
                onChangePrice('800to1000', 800, 1000);
                break;
            case '1000to1500':
                onChangePrice('1000to1500', 1000, 1500);
                break;
            case '1500to2000':
                onChangePrice('1500to2000', 1500, 2000);
                break;
            case '2000to3000':
                onChangePrice('2000to3000', 2000, 3000);
                break;
            case 'over3000':
                onChangePrice('over3000', 3000, Infinity);
                break;
            default:
                break;
        }
    };


    const fetchProductByParam = async () => {
        const param = {
            slug: slug,
            attributeId: '',
            min: min,
            max: max,
            page: 1
        };

        try {
            const { data } = await HomeService.getAllProductByCategory(param);
            if (data.message === 'success') {
                // console.log(data.data, 'data list cate');
                setSubCategory(data.data.categories);
                setAttributeGroups(data.data.attributeGroups);
                setProductManufacturers(data.data.productManufacturers);
                setProducts(data.data.products.data);
                setTotal(data.data.products.total)
            }
        } catch (error) {
            console.error('Error fetching search data:', error);
        }
    };

    const fetchCategoriesAndProd = async () => {
        const { data } = await HomeService.getAllProductByHome();

        if (data.message === 'success') {
            setListCateProd(data.data);
            // const categories = data.data;
        }

    };

    const fetchCategories = async () => {
        const result = await HomeService.getAllCategory();

        if (result.data.message === 'success') {
            setListCate(result.data.data);
            // console.log(result.data.data, 'data')
            const listCate1 = result.data.data;
            findCategoryNames(listCate1, slug)
        }

    };
    function findCategoryNames(categories: any[], targetSlug: any) {
        let result = { cateName: '', subCateName: '', subSubCateName: '' };

        function search(categories: any[], depth = 0) {
            for (const category of categories) {
                if (category.categoryNameSlug === targetSlug) {
                    if (depth === 0) {
                        setCateName(category.categoryName)
                        setCateNameSlug(category.categoryNameSlug)
                        // result.cateName = category.categoryName;
                    } else if (depth === 1) {
                        setSubCateName(category.categoryName)
                        setSubCateNameSlug(category.categoryNameSlug)
                        // result.subCateName = category.categoryName;
                    } else if (depth === 2) {
                        setSubSubCateName(category.categoryName)
                        setSubSubCateNameSlug(category.categoryNameSlug)
                        // result.subSubCateName = category.categoryName;
                    }
                    return true;
                }

                if (category.subcategories && category.subcategories.length > 0) {
                    if (search(category.subcategories, depth + 1)) {
                        if (depth === 0) {
                            setCateName(category.categoryName)
                            setCateNameSlug(category.categoryNameSlug)
                            // result.cateName = category.categoryName;
                        } else if (depth === 1) {
                            setSubCateName(category.categoryName)
                            setSubCateNameSlug(category.categoryNameSlug)
                            // result.subCateName = category.categoryName;
                        }
                        return true;
                    }
                }
            }
            return false;
        }

        search(categories);
        return result;
    }


    useEffect(() => {
        fetchProductByParam();
        fetchCategoriesAndProd();
        fetchCategories()

    }, []);

    const breadcrumbItems: any = [
        {
            title: <a href="/" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a>,
        },
        cateName && {
            title: <a href={`${cateNameSlug}`} className="hover:!text-colorPrimary hover:!bg-white">{cateName}</a>,
        },
        subCateName && subCateName.trim() && {
            title: <a href={`${subCateNameSlug}`} className="hover:!text-colorPrimary hover:!bg-white">{subCateName}</a>,
        },
        subSubCateName && subSubCateName.trim() && {
            title: <a href={`${subSubCateNameSlug}`} className="hover:!text-colorPrimary hover:!bg-white">{subSubCateName}</a>,
        }
    ].filter(Boolean);

    return (
        <>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='py-3.5 px-4 xs:-mx-4'>
                    <Breadcrumb
                        separator=">"
                        items={breadcrumbItems}
                    />
                </div>
            </div>
            <div className='container mx-auto xxxxl:w-full xxs:w-300  bg-gradient-to-r from-colorLinearF to-colorLinearT pt-5'>
                {/* <div className='bg-white p-4 sm:block hidden'>
                    <div className={`overflow-hidden ${showMore ? '' : 'max-h-64 relative'}`}>
                        {showMore === false && <div className={` content-[''] absolute bottom-0 left-0 right-0 h-36 bg-gradient-to-b from-transparent to-white`}></div>}

                        <div className='text-xl font-semibold mb-2'>
                            Mua PC Gaming - PC Streaming chính hãng Giá Tốt  tại PCM
                        </div>
                        <div className='text-sm mb-4'>
                            Nếu bạn là một người đam mê chơi game , một streamer hay một Game thủ chuyên nghiệp thì khái niệm PC Gaming không còn quá xa lạ với bạn . Tuy nhiên, để có thể lựa chọn cho mình một bộ máy tính chơi game có cấu hình tốt, phù hợp và tiết kiệm chi phí thì lại không hề đơn giản chút nào.
                        </div>
                        <div className='mb-4'>
                            <img src="https://pcmarket.vn/media/lib/18-12-2023/pcgamingpcm_6.jpg" alt="" className='w-250 mx-auto' />
                        </div>
                        <div className='text-sm mb-4'>
                            Nếu bạn là một người đam mê chơi game , một streamer hay một Game thủ chuyên nghiệp thì khái niệm PC Gaming không còn quá xa lạ với bạn . Tuy nhiên, để có thể lựa chọn cho mình một bộ máy tính chơi game có cấu hình tốt, phù hợp và tiết kiệm chi phí thì lại không hề đơn giản chút nào.
                        </div>
                        <div className='mb-4'>
                            <img src="https://pcmarket.vn/media/lib/18-12-2023/pcgamingpcm_6.jpg" alt="" className='w-250 mx-auto' />
                        </div>
                        <div className='text-sm mb-4'>
                            Nếu bạn là một người đam mê chơi game , một streamer hay một Game thủ chuyên nghiệp thì khái niệm PC Gaming không còn quá xa lạ với bạn . Tuy nhiên, để có thể lựa chọn cho mình một bộ máy tính chơi game có cấu hình tốt, phù hợp và tiết kiệm chi phí thì lại không hề đơn giản chút nào.
                        </div>
                        <div className='mb-4'>
                            <img src="https://pcmarket.vn/media/lib/18-12-2023/pcgamingpcm_6.jpg" alt="" className='w-250 mx-auto' />
                        </div>
                    </div>
                    <div className='flex justify-center items-center cursor-pointer mt-2' onClick={() => setShowMore(!showMore)}>
                        <div className='uppercase text-colorSecondary font-semibold mr-2'>{showMore ? 'Thu gọn' : 'Xem thêm'}</div>
                        {showMore ? <ArrowUpIconComponent style='w-4 h-4 fill-colorSecondary' /> : <ArrowDownCircleIconComponent style='w-4 h-4 fill-colorSecondary' />}

                    </div>
                </div> */}
                <div className='flex flex-wrap h-full bg-white'>
                    <div className='lg2:w-1/5 w-full h-full  '>
                        <div className='  py-2.5 bg-white px-4'>
                            <div className='mb-2.5 overflow-hidden sm:block hidden'>
                                <div className='uppercase font-semibold text-center border border-gray py-1.5 mb-2.5'>Lọc sản phẩm</div>
                                <div className='border-b border-b-gray mb-1.5 font-semibold leading-9'>{subSubCateName ? subSubCateName : subCateName ? subCateName : cateName}</div>
                                <div className='my-2'>
                                    {subCategory.map((subCate: any, index: number) => {
                                        return (
                                            <>
                                                <a href={`/danh-muc/${subCate.categoryNameSlug}`} key={subCate.id} className='border-b border-b-gray border-dashed mb-1.5 font-semibold lg2:leading-9 lg2:text-base text-sm flex items-center cursor-pointer'>
                                                    <ArrowDownRightIconComponent style='w-4 h-4 fill-black mr-2' />
                                                    {subCate.categoryName}
                                                </a>
                                            </>
                                        )
                                    })}
                                </div>
                                {productManufacturers.length > 0 &&
                                    <>
                                        <div className='mb-4 sm:block hidden'>
                                            <div className='border-b border-b-gray mb-1.5 font-semibold leading-9 uppercase'>Hãng sản xuất</div>
                                            {productManufacturers.map((prodManu: any, index: number) => {
                                                return (
                                                    <div key={prodManu.id}>

                                                        <Checkbox onChange={onChange}>{prodManu.prdManufacturerName}</Checkbox>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </>
                                }
                                <div className='mb-4 flex-col sm:flex hidden'>
                                    <div className='border-b border-b-gray mb-1.5 font-semibold leading-9 uppercase'>Khoảng giá</div>
                                    <Checkbox
                                        checked={selectedValue === 'under200'}
                                        onChange={() => onChangePrice('under200', 0, 200)}
                                    >
                                        Dưới 200kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '200to500'}
                                        onChange={() => onChangePrice('200to500', 200, 500)}
                                    >
                                        200kw - 500kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '500to800'}
                                        onChange={() => onChangePrice('500to800', 500, 800)}
                                    >
                                        500kw - 800kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '800to1000'}
                                        onChange={() => onChangePrice('800to1000', 800, 1000)}
                                    >
                                        800kw - 1.000kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '1000to1500'}
                                        onChange={() => onChangePrice('1000to1500', 1000, 1500)}
                                    >
                                        1.000kw - 1.500kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '1500to2000'}
                                        onChange={() => onChangePrice('1500to2000', 1500, 2000)}
                                    >
                                        1.500kw - 2.000kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '2000to3000'}
                                        onChange={() => onChangePrice('2000to3000', 2000, 3000)}
                                    >
                                        2.000kw - 3.000kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === 'over3000'}
                                        onChange={() => onChangePrice('over3000', 3000, Infinity)}
                                    >
                                        Trên 3.000kw
                                    </Checkbox>
                                </div>

                                {/* {attributeGroups.length > 0 &&
                                    <div className='mb-4 sm:block hidden '>
                                        <div className='border-b border-b-gray mb-1.5 font-semibold leading-9 uppercase'>Lọc sản phẩm</div>
                                        <div className='flex flex-col'>
                                            {attributeGroups.map((attributeGroup: any, index: number) => {
                                                return (
                                                    <>
                                                        {attributeGroup.attributes.map((attribute: any, index: number) => {
                                                            return (
                                                                <>
                                                                    <div className=' my-2 font-semibold text-sm'>{attributeGroup.attributeGroupName}</div>
                                                                    <Checkbox onChange={onChange}>{attribute.attributeName}</Checkbox>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })}


                                        </div>

                                    </div>
                                } */}


                            </div>
                            <div className='my-2 -mx-2 sm:hidden block'>
                                {subCategory.map((subCate: any, index: number) => {
                                    return (
                                        <>
                                            <a href={`/danh-muc/${subCate.categoryNameSlug}`} key={subCate.id} className='rounded-lg bg-black/20 py-2 px-3 m-2 font-semibold lg2:leading-9 lg2:text-base text-sm flex items-center cursor-pointer'>
                                                {/* <ArrowDownRightIconComponent style='w-4 h-4 fill-black mr-2' /> */}
                                                {subCate.categoryName}
                                            </a>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='lg2:w-4/5 w-full h-full  '>
                        <div className='bg-white px-4'>
                            <div className='py-4 flex justify-between items-center text-sm border-b border-gray'>
                                <div className='sm:flex hidden items-center font-semibold sm:text-base text-xs'>
                                    Tìm thấy
                                    <div className='text-colorSecondary mx-1 sm:text-base text-xs'>{total}</div>
                                    sản phẩm
                                </div>
                                <div className='flex flex-wrap'>
                                    {productManufacturers.length > 0 &&
                                        <>
                                            <div className='mr-2 mb-2 sm:hidden block'>
                                                <Select
                                                    showSearch
                                                    // className='w-52'
                                                    placeholder="Hãng"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={productManufacturers.map((prodManu: any) => ({
                                                        value: prodManu.id,
                                                        label: prodManu.prdManufacturerName,
                                                    }))}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className='mr-2 mb-2 sm:hidden block'>
                                        <Select
                                            className='w-full'
                                            placeholder="Chọn khoảng giá"
                                            value={selectedValue}
                                            onChange={handlePriceChange}
                                            options={[
                                                { value: 'under200', label: 'Dưới 200kw' },
                                                { value: '200to500', label: '200kw - 500kw' },
                                                { value: '500to800', label: '500kw - 800kw' },
                                                { value: '800to1000', label: '800kw - 1.000kw' },
                                                { value: '1000to1500', label: '1.000kw - 1.500kw' },
                                                { value: '1500to2000', label: '1.500kw - 2.000kw' },
                                                { value: '2000to3000', label: '2.000kw - 3.000kw' },
                                                { value: 'over3000', label: 'Trên 3.000kw' },
                                            ]}
                                        />
                                    </div>
                                    {/* <div className='sm:mr-0 sm:mb-0 mr-2 mb-2'>
                                        <Select
                                            showSearch
                                            // className='w-52'
                                            placeholder="Sắp xếp theo"
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                                            }
                                            options={[
                                                {
                                                    value: '1',
                                                    label: 'Tất cả',
                                                },
                                                {
                                                    value: '2',
                                                    label: 'Mới nhất',
                                                },
                                                {
                                                    value: '3',
                                                    label: 'Giá tăng dần',
                                                },
                                                {
                                                    value: '4',
                                                    label: 'Giá giảm dần',
                                                },
                                                {
                                                    value: '5',
                                                    label: 'Lượt xem',
                                                },
                                                {
                                                    value: '6',
                                                    label: 'Trao đổi',
                                                },
                                                {
                                                    value: '7',
                                                    label: 'Đánh giá',
                                                },
                                                {
                                                    value: '8',
                                                    label: 'Tên từ A - Z',
                                                },
                                            ]}
                                        />
                                    </div> */}
                                    {/* {attributeGroups.length > 0 &&
                                        <div className=' mb-2 sm:hidden block w-full'>
                                            <Select
                                                mode="multiple"
                                                className='w-full'
                                                showSearch
                                                placeholder="Tính năng"
                                                optionFilterProp="label"
                                                filterSort={(optionA, optionB) =>
                                                    String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                                                }
                                                options={attributeGroups.map((attributeGroup: any) => ({
                                                    label: attributeGroup.attributeGroupName,
                                                    options: attributeGroup.attributes.map((attribute: any) => ({
                                                        value: attribute.attributeGroupId,
                                                        label: attribute.attributeName,
                                                    })),
                                                }))}
                                            />

                                        </div>
                                    } */}
                                </div>


                            </div>
                            <div className='flex flex-wrap -mx-2'>
                                {products.map((product: any, index: number) => {
                                    const specification = product.specification;
                                    const arraySpecification = specification !== '' && specification.split('|');
                                    const promotion = product.promotion;
                                    const arrayPromotion = promotion !== '' && promotion.split('|');
                                    const img = product.logo;
                                    const arrayImg = img.split('|');
                                    const fixedPrice = product.fixedPrice;
                                    const price = product.price;
                                    let discount: number;
                                    if (product.fixedPrice < product.price) {
                                        discount = 0;
                                    } else {
                                        discount = Math.round(((product.fixedPrice - product.price) / product.fixedPrice) * 100);
                                    }
                                    return (
                                        <div key={product.id}>
                                            <ItemCardComponent
                                                id={product.id}
                                                title={product.prodName}
                                                slug={product.prodNameSlug}
                                                image={arrayImg[0]}
                                                price={price}
                                                fixedPrice={fixedPrice}
                                                discount={discount}
                                                guarantee={product.quarantee}
                                                desc={arraySpecification}
                                                promotion={arrayPromotion}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <Pagination align="end" defaultCurrent={1} total={total} className='py-4' />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ListProductForm
