/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import NewsService from '../../../../services/news';
import ProductAttributeService from '../../../../services/product-attributes';

const { Option } = Select;

const ManageProductAttibute = () => {
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = (record: any) => {
        // console.log(record.id, 'id')
        setIsModalOpen(true);
        setEditingItem(record.id);
        form.setFieldsValue(record);
    };

    const handleDelete = async (key: number) => {
        const obj = {
            id: key
        }
        const result = await ProductAttributeService.deleteProductAttributeCMS(obj);
        // console.log(result.response?.data, 'result form')
        if (result.response?.data.message === "success") {
            api.success({
                message: 'Xóa thành công thuộc tính sản phẩm!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
        fetchListManufacture();
    };

    const handleOk = async () => {
        try {
            // Validate form fields
            const values = await form.validateFields();

            let result;
            if (editingItem) {
                // Update existing product attribute
                const obj = {
                    id: editingItem,
                    name: values.attributeName,
                    status: values.status
                };
                result = await ProductAttributeService.updateProductAttributeCMS(obj);
            } else {
                // Create new product attribute
                const obj = {
                    name: values.attributeName,
                    status: values.status
                };
                result = await ProductAttributeService.createProductAttributeCMS(obj);
            }

            // Check API response
            if (result.response?.data.message === "success") {
                api.success({
                    message: editingItem ? 'Cập nhật thành công thuộc tính sản phẩm!' : 'Tạo thành công thuộc tính sản phẩm mới!',
                    showProgress: true,
                    pauseOnHover: true,
                });
            } else {
                // Handle error response from API
                api.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.response?.data.message || 'Unknown error',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }

            // Refresh data and close modal
            fetchListManufacture();
            setIsModalOpen(false);
        } catch (error) {

            // Handle error during form validation or API call
            api.error({
                message: 'Vui lòng nhập đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };



    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchListManufacture = async () => {

        try {
            const result = await ProductAttributeService.getAllProductAttributeCMS();
            // console.log(result.data, 'result')
            if (result.data.message === 'success') {
                setData(result.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchListManufacture();
    }, []);

    const columns = [

        {
            title: 'Tên',
            dataIndex: 'attributeName',
            key: 'attributeName',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                if (status === 'Active') {
                    return <div className='text-success'>Đã xác nhận</div>;
                } else if (status === 'Deactivate') {
                    return <div className='text-colorThird'>Chưa xác nhận</div>;
                } else if (status === 'Deleted') {
                    return <div className='text-colorThird'>Đã xóa</div>;
                } else {
                    return 'Trạng thái không xác định';
                }
            },
        },
        // {
        //     title: 'Mô tả',
        //     dataIndex: 'description',
        //     key: 'description',
        // },
        // {
        //     title: 'Đường dẫn',
        //     dataIndex: 'slug',
        //     key: 'slug',
        // },
        {
            title: '',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link"
                        onClick={() => handleEdit(record)}
                    >
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm thuộc tính sản phẩm
            </Button>
            <Table columns={columns} dataSource={data} rowKey="id" />

            <Modal
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                open={isModalOpen}
                width={1000}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                onCancel={handleCancel}>
                <Form form={form} layout="vertical">
                    <Form.Item name="attributeName" label="Tên" rules={[{ required: true, message: 'Hãy nhập tên!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Trạng thái"
                        rules={[{ required: true, message: 'Hãy chọn trạng thái!' }]}
                    >
                        <Select placeholder="Chọn trạng thái">
                            <Option value="Active">Đã xác nhận</Option>
                            <Option value="Deactivate">Chưa xác nhận</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageProductAttibute;
