/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Button, notification, Popover, Table, TableColumnsType } from "antd";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../assets/icons/CheckIcon";
import ACheckIcon from "../../assets/icons/ACheckIcon";
import ACheckIconblack from "../../assets/icons/ACheckIconblack";
import SettingIconComponent from "../../assets/icons/SettingIcon";
import GiftIcon from "../../assets/icons/GiftIcon";
import PlusIconComponent from "../../assets/icons/PlusIcon";
import DesProduct from "./description-product";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import HomeService from "../../services/home";
import MinusIcon from "../../assets/icons/MinusIcon";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import "quill-table/dist/quill.table.css";
// import { Editor, EditorState } from "draft-js";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "ckeditor5";

interface DetailsPcProps {
  prodNameSlug?: string;
  // id?: string;
  // slug: string;
}

// interface EditorRefs {
//   CKEditor?: typeof CKEditor;
//   ClassicEditor?: typeof ClassicEditor;
// }

function DetailsPC(props: DetailsPcProps) {
  const { prodNameSlug } = props;
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );

  // const { slug, id } = useParams<{ slug: string; id: string }>();
  // console.log("Slug:", slug);
  // console.log("ID:", id);
  // const id = "2";
  // console.log("prodNameSlug", prodNameSlug);
  // const editorRef = useRef<any>({});

  // useEffect(() => {
  //   editorRef.current = {
  //     CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
  //     ClassicEditor: require("@ckeditor/ckeditor5-build-classic").default,
  //   };
  // }, []);

  // const { CKEditor: Editor, ClassicEditor: EditorBuild } = editorRef.current;

  interface attributes {
    attributeGroupId: number;
    attributeName: string;
    discount: number;
    fixedPrice: number;
    id: number;
    logo: string;
    price: number;
    productId: number;
    stockQuantity: number;
    isSelected: boolean;
  }

  interface attributeGroups {
    attributes: attributes[];
    attributeGroupName: string;
    id: number;
    productId: number;
  }

  interface ProductDetail {
    id: number | undefined;
    prodName: string;
    prodNameSlug: string;
    logo: string;
    fixedPrice: number;
    price: number;
    description: string;
    promotion: string;
    quantity: number;
    specification: string;
    details: string;
    video: string;
    status: string;
    attributeGroups: attributeGroups[];
  }

  // const [editorState, setEditorState] = useState<EditorState>(
  //   EditorState.createEmpty()
  // );

  // const handleChange = (state: any) => {
  //   setEditorState(state);
  // };

  const [quantity, setQuantity] = useState(1);
  const [editorContent, setEditorContent] = useState("");

  const handleIncrease = () => {
    setQuantity((prevQuantity) => {
      return prevQuantity + 1;
    });
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => (prevQuantity > 0 ? prevQuantity - 1 : 0));
  };
  // const { id } = useParams<{ id: string }>();

  const [productDetail, setProductDetail] = useState<ProductDetail | null>(
    null
  );
  const initialPrice = productDetail?.fixedPrice || 0;
  const [totalAll, setTotalAll] = useState<number>(initialPrice);

  const [selectedCPU, setSelectedCPU] = useState<number>(0);

  // const getProductById = (id: number) => {
  //   HomeService.getProductById(id).then((response: any) => {
  //     setProductDetail(response.response.data.data);
  //   });
  // };
  const getProductBySlug = async (prodNameSlug: string) => {
    // HomeService.getProductBySlug(prodNameSlug).then((response: any) => {
    //   // console.log("response", response);
    //   setProductDetail(response.response.data.data);
    // });

    try {
      const response : any = await HomeService.getProductBySlug(prodNameSlug);
      setProductDetail(response.response.data.data);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };
  // console.log("producdetails", productDetail)

  useEffect(() => {
    if (prodNameSlug) {
      getProductBySlug(prodNameSlug);
    }
  }, [prodNameSlug]);

  const imageArray = productDetail?.logo.split("|");
  const specificationArray = productDetail?.specification.split("|");
  const promotionArray = productDetail?.promotion.split("|");
  // console.log("promotionArray", promotionArray)


  const url = productDetail?.video;
  const parts = url?.split('/');
  const videoId = parts?.[parts.indexOf('video') + 1];

  Fancybox.bind("[data-fancybox='gallery']", {
    buttons: ["zoom", "close"],
  } as any);

  interface DataType {
    key: React.Key;
    stt: number;
    productCode: string;
    description: string;
    quantity: number;
    guarantee: string;
  }

  // const [data, setData] = useState<string | undefined>(undefined);

  // const specificationArray: [] | undefined = undefined;
  const data: DataType[] | undefined = specificationArray?.map(
    (item, index) => ({
      key: index,
      stt: index + 1,
      productCode: "",
      description: item,
      quantity: 1,
      guarantee: "36th",
    })
  );
  const [attributeGroups, setAttributesGroups] = useState(
    productDetail?.attributeGroups || []
  );
  const [orderInfor, setOrderInfor] = useState([]);
  useEffect(() => {
    if (productDetail?.attributeGroups) {
      setAttributesGroups(productDetail.attributeGroups);
    }
  }, [productDetail]);
  // console.log("isSelected", productDetail?.attributeGroups[0].attributes[0].isSelected)
  const [isSelected, setIsSelected] = useState<boolean>(false);
  // const[nameSelected, setNameSelected] = useState<string>("")
  // const[nameGroup, setNameGroup] = useState<string>("")
  const [selectedAttributes, setSelectedAttributes] = useState<{
    [key: string]: boolean;
  }>({});


  const handleClick = (groupIndex: any, attributeIndex: any) => {
    setAttributesGroups((prevAttributes) => {
      const updatedAttributes = [...prevAttributes];

      if (
        updatedAttributes[groupIndex] &&
        updatedAttributes[groupIndex].attributes
      ) {
        updatedAttributes[groupIndex].attributes = updatedAttributes[
          groupIndex
        ].attributes.map((attr, idx) => {
          if (idx === attributeIndex) {
            return { ...attr, isSelected: true };
          } else {
            return { ...attr, isSelected: false };
          }
        });
      }
      // calculateTotalPrice(updatedAttributes)
      return updatedAttributes;
    });
  };

  const calculateTotalPrice = (attributeGroups: any) => {
    const selectedAttributes: any = [];
    attributeGroups.forEach((element: any) => {
      const filteredAttributes = element.attributes.filter(
        (attr: any) => attr.isSelected
      );
      selectedAttributes.push(...filteredAttributes);

      return selectedAttributes;
    });
    const totalPrice = selectedAttributes.reduce(
      (sum: any, attr: any) => sum + attr.price,
      0
    );
    if (productDetail?.price !== 0) {
      setTotalAll((totalPrice + productDetail?.price) * quantity);
  } else {
      setTotalAll((totalPrice + productDetail?.fixedPrice) * quantity);
  }

    setOrderInfor(selectedAttributes);
  };
  // console.log("quantity", quantity);
  // console.log("attributes", attributeGroups);
  // console.log("totalPrice", totalAll);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    calculateTotalPrice(attributeGroups);
  }, [attributeGroups, quantity]);

  // localStorage.setItem("attributeGroups", JSON.stringify(attributeGroups));

  const columns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
    },
    {
      title: "Mô tả thiết bị",
      dataIndex: "description",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
    },
  ];

  // const formatFixedPrice = productDetail?.fixedPrice
  //   ? productDetail?.fixedPrice.toLocaleString("ko-KR")
  //   : "Chưa có giá trị";

    let formatFixedPrice;
    if (productDetail?.price !==0 ) {
      formatFixedPrice = productDetail?.price
    ? productDetail?.price.toLocaleString("ko-KR")
    : "Chưa có giá trị";
  } else {
    formatFixedPrice = productDetail?.fixedPrice
    ? productDetail?.fixedPrice.toLocaleString("ko-KR")
    : "Chưa có giá trị";
  }

  const formatTotalAll = totalAll
    ? totalAll.toLocaleString("ko-KR")
    : formatFixedPrice;

  const orderInforTotalFormCard = JSON.parse(
    localStorage.getItem("orderInforTotalFormCard") || "[]"
  );

  // const saveOrderLocalStorage = () => {
  //   if (quantity === 0) {
  //     api.warning({
  //       message: 'Hãy chọn số lượng sản phẩm!',
  //       showProgress: true,
  //       pauseOnHover: true,
  //     });
  //     return; 
  //   }
  //   const orderInforTotal = {
  //     namepc: productDetail?.prodName,
  //     logo: productDetail?.logo,
  //     attributeGroups: attributeGroups,
  //     orderInfor: orderInfor,
  //     totalPrice: totalAll,
  //     quantity: quantity,
  //   };

  //   localStorage.setItem("orderInforTotal", JSON.stringify(orderInforTotal));
  // };

  // const [totalQuantity,setTotalQuantity] = useState<number>();
  const storedTotalQuantity = localStorage.getItem("totalQuantity");

  // Parse từ JSON về số nguyên
  const totalQuantity = storedTotalQuantity ? JSON.parse(storedTotalQuantity) : 0;
  const navigate = useNavigate();
  const handleSave = () => {
    if (quantity === 0) {
      api.warning({
        message: 'Hãy chọn số lượng sản phẩm!',
        showProgress: true,
        pauseOnHover: true,
      });
      return;
    }
    const orderInforTotal = {
      id: productDetail?.id,
      namepc: productDetail?.prodName,
      prodNameSlug: productDetail?.prodNameSlug,
      logo: productDetail?.logo,
      attributeGroups: attributeGroups,
      orderInfor: orderInfor,
      totalPrice: totalAll,
      quantity: quantity,
    };

    localStorage.setItem("orderInforTotal", JSON.stringify(orderInforTotal));
    api.success({
      message: 'Đặt hàng thành công!',
      showProgress: true,
      pauseOnHover: true,
    });
    const totalQuantity1 = orderInforTotalFormCard.reduce(
      (total: any, item: any) => total + item.quantity, 0
    );
    localStorage.setItem("totalQuantity", JSON.stringify(totalQuantity1));

    window.dispatchEvent(new Event('storage'));
    navigate('/order');
    window.location.reload();
  };
  const handleOrder = () => {
    if (quantity === 0) {
      api.warning({
        message: 'Hãy chọn số lượng sản phẩm!',
        showProgress: true,
        pauseOnHover: true,
      });
      return;
    }
    const orderInforTotal = {
      id: productDetail?.id,
      namepc: productDetail?.prodName,
      logo: productDetail?.logo,
      attributeGroups: attributeGroups,
      orderInfor: orderInfor,
      totalPrice: totalAll,
      quantity: quantity,
    };

    const existingProductIndex = orderInforTotalFormCard.findIndex(
      (item: any) => item.namepc === productDetail?.prodName
    );

    if (existingProductIndex !== -1) {
      orderInforTotalFormCard[existingProductIndex].quantity += quantity;
    } else {
      orderInforTotalFormCard.push(orderInforTotal);
    }
    // const totalQuantity1 = orderInforTotalFormCard.reduce(
    //   (total : any, item:any) => total + item.quantity, 0
    // );
    // localStorage.setItem("totalQuantity", JSON.stringify(totalQuantity1));

    localStorage.setItem('orderInforTotalFormCard', JSON.stringify(orderInforTotalFormCard));
    api.success({
      message: 'Thêm sản phẩm vào giỏ hàng thành công!',
      showProgress: true,
      pauseOnHover: true,
    });
    const totalQuantity1 = orderInforTotalFormCard.reduce(
      (total: any, item: any) => total + item.quantity, 0
    );
    localStorage.setItem("totalQuantity", JSON.stringify(totalQuantity1));
    window.dispatchEvent(new Event('storage'));
  }
  const totalQuantity1 = orderInforTotalFormCard.reduce(
    (total: any, item: any) => total + item.quantity, 0
  );
  localStorage.setItem("totalQuantity", JSON.stringify(totalQuantity1));
  // console.log("totalQuantity",totalQuantity)

  //   useEffect(() => {

  //     handleOrder();

  //     window.addEventListener('storage', handleOrder);

  //     return () => {
  //         window.removeEventListener('storage', handleOrder);
  //     };
  // }, []);

  return (
    <div className="pt-5 pb-5 ">
      {contextHolder}
      {/* <div className="lg2:ml-28 xs:ml-4 pt-4">Trang chủ </div> */}
      <div className="flex flex-col xs:flex-col md:flex-row m-4 border border-colorBorder p-8 rounded-xl shadow-lg bg bg-white">
        <div className="lg2:w-full md:mr-5 xs:w-full w-full">
          <a href={selectedImage || imageArray?.[0]} data-fancybox="gallery">
            <div
              className="w-full xl:h-96 md:h-80 h-96 border-2 border-white mb-5 bg-center"
              style={{
                backgroundImage: `url(${selectedImage || imageArray?.[0]})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat',
              }}
            >
              {/* {!selectedImage && <span>Click an image below</span>} */}
            </div>
          </a>
          <div className="flex space-x-2 flex-wrap">
            {imageArray?.map((img, index) => (
              // <a key={index} href={img} data-fancybox="gallery">
              <img
                key={index}
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className="xs:w-20 xs:h-20 w-10 h-10 cursor-pointer border-2 border-white rounded-sm border-transparent hover:border-blue-500"
                onClick={() => setSelectedImage(img)}
              />
              // </a>
            ))}
          </div>
        </div>
        <div className="flex-col w-full mt-5 xs:ml-0">
          <div className="font-bold text-2xl mb-3">
            {productDetail?.prodName}
          </div>

          <div className="mb-3">
            <div className="flex flex-col lg2:flex-row xs:flex-col justify-between border rounded-md bg bg-colorSecondary text-white p-4 mb-3">
              <div className="font-semibold xs:text-xl text-md">
                Giá tiền : ₩{formatTotalAll}
              </div>
              <div className="font-semibold xs:text-xl text-md">
                {/* Giá nâng cấp: ₩{formatTotalAll} */}
              </div>
              {/* <div className="font-semibold text-xl">Tiết kiệm:2.310.000đ</div> */}
            </div>
            {/* <div className="flex justify-center text-sm items-center bg bg-colorYellow rounded-md w-40 h-8">
              Bảo hành : 36 tháng
            </div> */}
          </div>

          {(specificationArray || []).length > 0 && specificationArray?.[0] && (
          <div className="mb-5">
            <div className="font-bold text-sm mb-3">Mô tả sản phẩm</div>
            <div className="flex-col md:text-md text-sm">
              {specificationArray?.map((item) => (
                <div className="flex">
                  <div className="w-6 h-6">
                    <CheckIcon style="w-6 h-6 stroke-colorThird" />
                  </div>
                  <div className="ml-2">{item}</div>
                </div>
              ))}
            </div>
          </div>
          )}

          {(productDetail?.attributeGroups||[]).length > 0 && productDetail?.attributeGroups?.[0] && (
          <div className="border border-colorBorder rounded-md mb-5">
            <div className="flex bg bg-colorBrown p-2">
              <div className="mr-2">
                <SettingIconComponent />
              </div>
              <div className="text-colorThird font-semibold">PHÂN LOẠI</div>
            </div>

            <div className="flex p-2 mb-2 flex-col">
              {productDetail?.attributeGroups?.map(
                (productItem, index: number) => (
                  <div
                    key={productItem.attributeGroupName}
                    className="mr-5 flex mb-5  text-sm font-semibold"
                  >
                    <div className="mr-5 flex justify-center items-center text-sm font-semibold">
                      {productItem.attributeGroupName}
                    </div>

                    <div className="flex flex-wrap">
                      {productDetail?.attributeGroups?.[index]?.attributes?.map(
                        (processor, attributeIndex) => (
                          <div
                            key={`${productItem.id}-${attributeIndex}`}
                            className={`relative border border-colorBorder mb-3 xs:mb-3 lg:mb-0 rounded-md p-2 mr-5 cursor-pointer ${selectedCPU === attributeIndex
                              ? "border-blue-500"
                              : ""
                              }`}
                            onClick={() => handleClick(index, attributeIndex)}
                          >
                            {attributeGroups[index]?.attributes?.[
                              attributeIndex
                            ]?.isSelected && (
                                <div className="absolute top-1/4 right-0 bg-blue-500 text-colorSecondary text-xs w-0 h-0 border-l-[30px] border-l-transparent border-b-[30px] border-b-blue-500">
                                  <span className="absolute top-0 left-1/2 transform -translate-x-full translate-y-1/4 text-white text-base font-bold pr-0.5">
                                    ✓
                                  </span>
                                </div>
                              )}
                            {processor?.attributeName}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          )}

          {(promotionArray || []).length > 0 && promotionArray?.[0] && (
          <div className="border border-colorBorder rounded-md">
            <div className="flex bg bg-colorBrown p-2">
              <div className="mr-2">
                <GiftIcon />
              </div>
              <div className="text-colorThird font-semibold flex justify-center items-center">
                KHUYẾN MÃI
              </div>
            </div>
            <div className="p-2 md:text-md text-sm">
              {promotionArray?.map((item:any, index:number) => (
                <div className="flex mb-3">
                  <div className="text-white bg bg-colorThird rounded-full w-6 h-6 flex justify-center items-center mr-3">
                    <div className="flex justify-center items-center w-6 h-6">
                      {index + 1}
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>
          )}

          <div className="flex mt-5 justify-between">
            <div className="font-semibold mr-3 flex items-center xs:text-md text-sm">
              Số lượng:
            </div>
            <div className="flex items-center justify-center">
              <div
                id="quantity"
                className="font-semibold xs:w-20 xs:h-8 w-14 h-8 rounded-md flex justify-center items-center border border-colorBorder mr-3"
              >
                {quantity}
              </div>
              <div
                className="w-8 h-8 rounded-md flex justify-center items-center border border-colorBorder mr-3"
                onClick={handleIncrease}
              >
                <PlusIconComponent style="stroke-colorPrimary w-6 h-6 cursor-pointer" />
              </div>
              <div
                className="w-8 h-8 rounded-md flex justify-center items-center border border-colorBorder mr-3"
                onClick={handleDecrease}
              >
                <MinusIcon style="stroke-colorPrimary w-6 h-6 cursor-pointer" />
              </div>
            </div>
          </div>

          <div className="mt-5 flex w-full justify-center ">
            <div className="w-full flex justify-between">
              {/* <Link to={`/order`} className="flex w-full"> */}
              <Button
                className="w-full h-8 text-sm bg bg-colorSecondary xs:w-5/6 md:h-14 md:text-xl font-bold text-white"
                onClick={handleSave}
              >
                ĐẶT HÀNG
              </Button>
              {/* </Link> */}
            </div>
            <div className="w-full flex justify-center">
              <Button className="w-5/6 h-8 text-sm bg bg-white xs:w-5/6 md:h-14 md:text-xl font-bold text-colorSecondary" onClick={handleOrder}>
                THÊM VÀO GIỎ
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-col justify-center bg bg-white m-4 border border-colorBorder p-8 rounded-xl shadow-lg mb-5">
        <div className="mb-3 font-bold md:text-xl text-md">
          THÔNG SỐ KỸ THUẬT
        </div>

        <div className="xs:overflow-auto overflow-auto ">
          <Table columns={columns} dataSource={data} pagination={false} size="large" />
        </div>
      </div>
      <div className="bg bg-white m-4 border border-colorBorder p-8 rounded-xl shadow-lg mb-5">
        <div dangerouslySetInnerHTML={{ __html: productDetail?.description ?? "" }}/> 
      </div>

      {videoId && (
      <div className="bg bg-white m-4 border border-colorBorder p-8 rounded-xl shadow-lg mb-5">
        <iframe 
          src={`https://www.tiktok.com/embed/${videoId}`}
          style={{ width: '100%', height: '800px', border: 'none' }}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" 
          allowFullScreen
        />
      </div>
      )}
    </div>
  );
}

export default DetailsPC;
