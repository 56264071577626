/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect, useRef } from "react";
import { Card, notification, Popover } from "antd";
import CartIconComponent from "../../assets/icons/CartIcon";
import DatabaseIconComponent from "../../assets/icons/DatabaseIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import GiftIconComponent from "../../assets/icons/GiftIcon";
import { useNavigate } from "react-router-dom";
import HomeService from "../../services/home";

const ItemListCardComponent = ({ products }: any) => {
  const [startTouchX, setStartTouchX] = useState<number | null>(null);
  const [currentTouchX, setCurrentTouchX] = useState<number | null>(null);
  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setStartTouchX(event.touches[0].clientX);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    setCurrentTouchX(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startTouchX !== null && currentTouchX !== null) {
      const diffX = startTouchX - currentTouchX;
      if (Math.abs(diffX) > 50) {
        if (diffX > 0) {
          nextSlide();
        } else {
          prevSlide();
        }
      }
    }
    setStartTouchX(null);
    setCurrentTouchX(null);
  };
  const getSlidesToShow = () => {
    const width = window.innerWidth;
    if (width >= 1600) return 6;
    if (width >= 1400) return 5;
    if (width >= 1200) return 4;
    // if (width >= 992) return 3;
    if (width >= 576) return 3;
    if (width >= 425) return 2;
    return 2;
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(getSlidesToShow());
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      if (nextIndex >= products.length) {
        return 0;
      }
      return nextIndex;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const prevIndexWithOffset = prevIndex - 1;
      if (prevIndexWithOffset < 0) {
        return products.length - 1;
      }
      return prevIndexWithOffset;
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const slidesToShow = getSlidesToShow();
      setItemsPerPage(slidesToShow);
      setCurrentIndex((prevIndex) => Math.min(prevIndex, Math.floor(products.length / slidesToShow)));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   startAutoSlide();
  //   return () => stopAutoSlide();
  // }, []);

  // const startAutoSlide = () => {
  //   if (!intervalRef.current) {
  //     intervalRef.current = setInterval(nextSlide, 8000);
  //   }
  // };

  // const stopAutoSlide = () => {
  //   if (intervalRef.current) {
  //     clearInterval(intervalRef.current);
  //     intervalRef.current = null;
  //   }
  // };

  // const renderedProducts = [
  //   ...products.slice(-itemsPerPage, 0),
  //   ...products,
  //   ...products.slice(itemsPerPage),
  // ];

  const renderedProducts = products;

  const getDetailProduct = async (slug: any) => {
    const result = await HomeService.getProductBySlug(slug);
    // console.log(result.response?.data, 'result');

    if (result.response?.data.message === 'success') {

      const existingDataString = localStorage.getItem('orderInforTotalFormCard');
      let existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];

      if (!Array.isArray(existingDataArray)) {
        existingDataArray = [];
      }

      const newProductData = {
        id: result.response?.data.data.id,
        attributeGroups: result.response?.data.data.attributeGroups,
        namepc: result.response?.data.data.prodName,
        prodNameSlug: result.response?.data.data.prodNameSlug,
        logo: result.response?.data.data.logo,
        quantity: 1,
        totalPrice: result.response?.data.data.fixedPrice
      };

      const existingProduct = existingDataArray.find((item: any) => item.id === newProductData.id);

      if (existingProduct) {
        existingProduct.quantity += 1;
        existingProduct.totalPrice = result.response?.data.data.fixedPrice * existingProduct.quantity;
      } else {
        existingDataArray.push(newProductData);
      }

      localStorage.setItem('orderInforTotalFormCard', JSON.stringify(existingDataArray));

      const totalQuantity = existingDataArray.reduce((total: number, item: any) => {
        return total + item.quantity;
      }, 0);

      localStorage.setItem('totalQuantity', totalQuantity.toString());
      api.success({
        message: 'Thêm sản phẩm vào giỏ hàng thành công!',
        showProgress: true,
        pauseOnHover: true,
      });
      window.dispatchEvent(new Event('storage'));
    }
  };


  return (
    <div className="flex items-center relative">
      {contextHolder}
      <button
        onClick={prevSlide}
        className={`bg-colorSecondary bg-opacity-80 md:block hidden hover:bg-opacity-100 rounded-full text-white border-none md:px-4 px-2 md:py-2.5 py-0.5 cursor-pointer absolute left-0 top-1/2 transform -translate-y-1/2 z-10 ${products.length <= 5 ? 'hidden' : ''
          }`}
      >
        &#10094;
      </button>
      <div className="overflow-hidden w-full ">
        <div
          className="flex transition-transform duration-2000 ease-linear "
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsPerPage)
              }%)`,
          }}
          // onMouseEnter={stopAutoSlide}
          // onMouseLeave={startAutoSlide}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {renderedProducts.map((product: any, index: number) => {
            // console.log(product.id)
            const img = product.logo;
            const arrayImg = img.split("|");
            let discount: number;
            if (product.fixedPrice < product.price) {
              discount = 0;
            } else {
              discount = Math.round(((product.fixedPrice - product.price) / product.fixedPrice) * 100);
            }
            return (
              <div
                key={index}
                className=" text-sm flex-shrink-0 px-2"
                style={{
                  width: `${100 / itemsPerPage}%`,
                }}
              >
                {/* {product.id} */}
                <Card
                  size="small"
                  hoverable
                  className=""
                  cover={
                    <Popover
                      content={() => {
                        const specification = product.specification;
                        const arraySpecification = specification !== "" && specification !== undefined && specification.split("|");
                        // console.log(arraySpecification.length, 'arraySpecification')
                        const promotion = product.promotion;
                        const arrayPromotion =
                          promotion !== "" && promotion.split("|");

                        return (
                          <div className="max-w-100 min-w-100 md:block hidden">
                            <div className="bg-colorSecondary -mx-3 -mt-3 px-3 py-4 mb-3 text-white text-lg font-semibold !font-notoSanCJK">
                              {product.prodName}
                            </div>
                            <div className="flex justify-between items-center">
                              <div className="font-bold text-sm !font-notoSanCJK">Giá bán:</div>
                              <div className="text-colorThird text-lg font-bold !font-notoSanCJK">
                                {(product.price).toLocaleString('ko-KR', {
                                  style: 'currency',
                                  currency: 'KRW',
                                })}
                              </div>
                            </div>
                            {/* <div className="flex justify-between items-center">
                              <div className="font-bold text-sm !font-notoSanCJK">Bảo hành:</div>
                              <div className="text-sm !font-notoSanCJK">12 tháng</div>
                            </div> */}
                            {arraySpecification.length > 0 && arraySpecification.length !== undefined && (
                              <>
                                <div className="items-center bg-colorSecondary rounded-lg px-3 py-2 my-2 inline-flex">
                                  <DatabaseIconComponent style="w-4 h-4 stroke-white mr-2" />
                                  <div className="text-sm font-bold text-white !font-notoSanCJK">
                                    Mô tả tóm tắt:
                                  </div>
                                </div>
                                <div className="my-2">
                                  {arraySpecification.map(
                                    (specification: any, index: number) => (
                                      <div
                                        key={index}
                                        className="flex items-start mb-1"
                                      >
                                        <div className="mr-2">
                                          <CheckIcon style="w-5 h-5 fill-colorSecondary" />
                                        </div>
                                        <div className="!font-notoSanCJK">{specification}</div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            )}

                            {arrayPromotion.length > 0 && (
                              <>
                                <div className="items-center bg-colorSecondary rounded-lg px-3 py-2 my-2 inline-flex">
                                  <GiftIconComponent style="w-4 h-4 fill-white mr-2" />
                                  <div className="text-sm font-bold text-white !font-notoSanCJK">
                                    Khuyến mãi:
                                  </div>
                                </div>
                                <div className="my-2">
                                  {arrayPromotion.map(
                                    (promotion: any, index: number) => (
                                      <div
                                        key={index}
                                        className="flex items-start mb-1"
                                      >
                                        <div className="mr-2">
                                          <CheckIcon style="w-5 h-5 fill-colorSecondary" />
                                        </div>
                                        <div className="text-black !font-notoSanCJK">
                                          {promotion}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        );
                      }}
                      trigger="hover"
                    >
                      <a
                        className="relative "
                        href={`/san-pham/${product.prodNameSlug}.html`}
                      >
                        <img
                          alt={product.prodName}
                          src={arrayImg[0]}
                          className=" rounded-t-lg w-full object-cover"
                        />
                        {discount !== 0 &&
                          <div className="absolute top-2 left-2 bg-gradient-to-r from-colorPrimary to-colorSecondary text-white px-2 py-1 text-sm font-semibold rounded">
                            {discount}%
                          </div>
                        }
                      </a>
                    </Popover>
                  }
                >
                  <a
                    href={`/san-pham/${product.prodNameSlug}.html`}
                    className="hover:text-colorThird text-sm duration-400 overflow-hidden text-ellipsis line-clamp-2 h-10"
                  >
                    {product.prodName}

                  </a>
                  <div className="flex justify-between items-center my-2">
                    <div>
                      <div className="text-colorThird xs:text-sm text-xs font-bold">
                        {(product.price).toLocaleString('ko-KR', {
                          style: 'currency',
                          currency: 'KRW',
                        })}
                      </div>
                      {product.fixedPrice !== product.price &&
                        <div className="text-xs line-through !font-notoSanCJK">{(product.fixedPrice).toLocaleString('ko-KR', {
                          style: 'currency',
                          currency: 'KRW',
                        })}</div>
                      }
                    </div>
                    <div className="bg-colorSecondary rounded-full w-6 h-6 flex items-center justify-center hover:bg-colorThird duration-400"
                      onClick={() => getDetailProduct(product.prodNameSlug)}
                    >
                      <CartIconComponent style="md:w-4 md:h-4 w-3.5 h-3.5 stroke-white" />
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
        </div >
      </div >
      <button
        onClick={nextSlide}
        className={`bg-colorSecondary bg-opacity-80 md:block hidden hover:bg-opacity-100 rounded-full text-white border-none md:px-4 px-2 md:py-2.5 py-0.5 cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2 z-10 ${products.length <= 5 ? 'hidden' : ''
          }`}
      >
        &#10095;
      </button>
    </div >
  );
};

export default ItemListCardComponent;
